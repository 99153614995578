$(function(){
    var form = $('.main-form'),
        message_popup = $('.message-popup');

    var getData = function(){
        var data = {};
        $.each(form.serializeArray(), function(index, value){
            var name = value.name,
                val = value.value;

            if(data.hasOwnProperty(name)){
                if($.isArray(data[name])){
                    data[name].push($.trim(val));
                }else{
                    data[name] = [data[name], $.trim(val)];
                }
            }else{
                data[name] = $.trim(val);
            }
        });
        return data;
    };

    var fails = function(data, rules){
        var errors = {},
            failed = false;

        $.each(data, function(key, value){
            var rseq = [];
            if(rules[key]){
                rseq = rules[key].split('|');
            }

            $.each(rseq, function(index, rule){
                if(rule === 'required'){
                    if($.isArray(value)){
                        if(!value.length){
                            errors[key] = errors[key] || [];
                            errors[key].push('Необходимо заполнить это поле');
                            failed = true;
                        }
                    }else{
                        if(!$.trim(value)){
                            errors[key] = errors[key] || [];
                            errors[key].push('Необходимо заполнить это поле');
                            failed = true;
                        }
                    }
                }else if(rule === 'email'){
                    if(value.indexOf('@') < 0){
                        errors[key] = errors[key] || [];
                        errors[key].push('Введен неправильный email');
                        failed = true;
                    }
                }
            });
        });

        return failed ? errors : failed;
    };

    var fails2 = function(data, rules){
        var errors = {},
            failed = false;

        $.each(rules, function(key, rule){
            var value = data[key];
            if(rule === 'required'){
                if($.isArray(value)){
                    if(!value.length){
                        errors[key] = errors[key] || [];
                        errors[key].push('Необходимо заполнить это поле');
                        failed = true;
                    }
                }else{
                    if(!$.trim(value)){
                        errors[key] = errors[key] || [];
                        errors[key].push('Необходимо заполнить это поле');
                        failed = true;
                    }
                }
            }else if(rule === 'email'){
                if(value.indexOf('@') < 0){
                    errors[key] = errors[key] || [];
                    errors[key].push('Введен неправильный email');
                    failed = true;
                }
            }
        });

        return failed ? errors : failed;
    };

    var resetErrors = function(){
        $('input, textarea, select', form).each(function(){

            $(this)
                .parents('.form-group')
                .removeClass('has-error');
        });
    };

    var reset = function(){
        $('input, textarea, select', from).each(function(){
            $(this)
                .val('');
        });
    };

    var setErrors = function(errors){
        $.each(errors, function(key, values){
            $('[name=' + key + ']', form)
                .parents('.form-group')
                .addClass('has-error');
        });
    };

    var showMessage = function(header, message){
        $('.message-header', message_popup).text(header);
        $('.message-text', message_popup).text(message);
        message_popup.addClass('enabled');
    };

    var send = function(data){
        console.log('sending');
        $.post('/requests/main', data, function(){
        }, 'json');


        showMessage(
            'Спасибо за Ваш интерес к Dahua',
            'Ваш запрос поступил в обработку. ' +
                'Наши менеджеры свяжутся с Вами в ближайшее время.'
        );
        yaCounter31650652.reachGoal('metrika_get_price');
    };

    $('.main-form-btn', form).on('click', function(ev){
        ev.preventDefault();
        var data = getData(),
            errors = fails2(data, {
                'customer_name': 'required',
                'customer_phone': 'required',
                'customer_agreement': 'required'
            });
        console.log("Main form data and errors:",data,errors);
        resetErrors();
        if(!errors){
            send(data)
            
        }else{
            setErrors(errors);
        }
    });
});