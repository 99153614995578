$(function(){
    var buy_buttons = $('.get-wholesale-price-button'),
        popup = $('.get-wholesale-price-popup'),
        message_popup = $('.message-popup');

    var getData = function(){
        var data = {};
        $.each($('.popup-form', popup).serializeArray(), function(index, value){
            var name = value.name,
                val = value.value;

            if(data.hasOwnProperty(name)){
                if($.isArray(data[name])){
                    data[name].push($.trim(val));
                }else{
                    data[name] = [data[name], $.trim(val)];
                }
            }else{
                data[name] = $.trim(val);
            }
        });
        return data;
    };

    var fails = function(data, rules){
        var errors = {},
            failed = false;

        $.each(data, function(key, value){
            var rseq = [];
            if(rules[key]){
                rseq = rules[key].split('|');
            }

            $.each(rseq, function(index, rule){
                if(rule === 'required'){
                    if($.isArray(value)){
                        if(!value.length){
                            errors[key] = errors[key] || [];
                            errors[key].push('Необходимо заполнить это поле');
                            failed = true;
                        }
                    }else{
                        if(!$.trim(value)){
                            errors[key] = errors[key] || [];
                            errors[key].push('Необходимо заполнить это поле');
                            failed = true;
                        }
                    }
                }else if(rule === 'email'){
                    if(value.indexOf('@') < 0){
                        errors[key] = errors[key] || [];
                        errors[key].push('Введен неправильный email');
                        failed = true;
                    }
                }
            });
        });

        return failed ? errors : failed;
    };

    var resetErrors = function(){
        $('.popup-form input, ' +
            '.popup-form textarea, ' +
            '.popup-form select', popup).each(function(){

            $(this)
                .parents('.form-group')
                .removeClass('has-error');
        });
    };

    var reset = function(){
        $('.popup-form input, ' +
            '.popup-form textarea, ' +
            '.popup-form select', popup).each(function(){

            $(this)
                .val('');
        });
    };

    var setErrors = function(errors){
        $.each(errors, function(key, values){
            $('[name=' + key + ']', popup)
                .parents('.form-group')
                .addClass('has-error');
        });
    };

    var showMessage = function(header, message){
        $('.message-header', message_popup).text(header);
        $('.message-text', message_popup).text(message);
        message_popup.addClass('enabled');
    };

    var send = function(data){
        console.log('wholesale');
        $.post('/requests/wholesale', data, function(){

        }, 'json');


        popup.removeClass('enabled');
        showMessage(
            'Спасибо за Ваш интерес к Dahua',
            'Ваш запрос поступил в обработку. ' +
                'Наши менеджеры свяжутся с Вами в ближайшее время.'
        );

        yaCounter31650652.reachGoal('opt_card');
    };

    buy_buttons.each(function(){
        var btn = $(this),
            product_id = btn.data('product-id'),
            product_title = btn.data('product-title');

        btn.on('click', function(ev){
        	
            reset();
            resetErrors();
            $('[name="customer_order"]', popup).val(product_title);
            popup.addClass('enabled');
        });
    });

    $('.cancel', popup).on('click', function(ev){
        ev.preventDefault();
        popup.removeClass('enabled');
    });

    $('.ok', popup).on('click', function(ev){
        ev.preventDefault();
        var data = getData(),
            errors = fails(data, {
                'customer_name': 'required',
                'customer_phone': 'required'
            });

        resetErrors();
        if(!errors){
            send(data)
        }else{
            setErrors(errors);
        }
    });
});